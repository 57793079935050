<template>
  <div class="bg-gray w-100 pt-2">
    <div class="d-flex w-100 mb-2">
      <div class="col-9">
        <b-form inline>
          <b-form-group>
            <b-form-input
              v-model="user_name"
              type="search"
              class="user-search"
              placeholder="Search Name"
            ></b-form-input>
          </b-form-group>
        </b-form>
      </div>
      <div class="col-3">
        <b-button variant="link" class="float-right">
          <span class="text-dark">
            <b-icon-trash-fill></b-icon-trash-fill>
          </span>
        </b-button>
        <b-button variant="link" class="float-right">
          <span class="text-dark">
            <b-icon-person-x-fill></b-icon-person-x-fill> <small>Deactivate</small>
          </span>
        </b-button>
      </div>
    </div>
    <b-table
      thead-class="d-none"
      show-empty
      empty-text="No Users Yet"
      :items="users"
      :busy="isBusy"
      :fields="fields"
      table-variant="light"
      bordered
      responsive
    >
      <template #cell(checkbox)="data">
        <b-form-checkbox v-model="data.item.selected"> </b-form-checkbox>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading Users...</strong>
        </div>
      </template>
      <template #cell(role)="data">
          <b-badge variant="danger" class="text-capitalize">{{data.item.role}}</b-badge>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      user_name: "",
      isBusy: false,
      fields: [
        { key: "checkbox",class:"user-checkbox" },
        {
          key: "name",
          class: "user-cell",
        },
        { key: "email", class: "user-cell" },
        { key: "phone_no", class: "user-cell" },
        {key:"role",class:'user-cell'},
        {
          key: "created_at",
          formatter: (value) => {
            return moment(value).format("MMM DD");
          },
        },
      ],
    };
  },
  computed: {
    users() {
      const status = this.$route.query["active"] || "true";
      return status==="true"
        ? this.$store.getters["rbac/activeUsers"]
        : this.$store.getters["rbac/inactiveUsers"];
    },
    selected() {
      return this.list.modules.filter((module) => module.selected);
    },
  },
  created() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.isBusy = true;
      this.$store.dispatch("rbac/loadUsers").then(() => {
        this.isBusy = false;
      });
    },
  },
};
</script>


<style scoped lang="scss">
.user-search {
  width: 33em;
  height: 2.33em;
  background-color: #96d3ec !important;
}
.user-search:not(:placeholder-shown) {
  background-color: #96d3ec !important;
}

.user-search:placeholder-shown {
  background-color: #ffffff !important;
}
</style>
<style lang="scss">
@import "@/assets/custom_scss/_variables.scss";
.user-cell {
  font-family: $font-primary;
  font-weight: 500;
}
.user-checkbox{
  width:1em;
}
</style>